import React, { useEffect, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const InfiniteScroll = ({ next, hasMore = false, offset = 800, loader = null, children = null, disabled = false }) => {
  const [visible, setVisible] = useState(false)
  const [fetch, setFetch] = useState(false)

  useEffect(() => {
    if (!visible || !hasMore || disabled || fetch) return

    // Execute next batch
    const op = next().finally(() => {
      // Clear current fetch after a delay to give time for re-render
      setTimeout(() => setFetch(null), 250)
    })

    // Set fetch op
    setFetch(op)
  }, [visible, hasMore, disabled, fetch])

  useEffect(() => {
    // Cancel current fetch after navigating away
    return () => fetch?.cancel?.()
  }, [])

  return (
    <div className='infinite-scroll-container'>
      {children}
      <VisibilitySensor
        partialVisibility
        intervalCheck={true}
        intervalDelay={200}
        scrollCheck={true}
        scrollThrottle={200}
        resizeCheck={true}
        resizeThrottle={200}
        offset={{ bottom: -offset, top: -offset }}
        onChange={(newValue) => setVisible(newValue)}
      >
        <div className='infinite-scroll-sensor'>{fetch && loader?.()}</div>
      </VisibilitySensor>
      <style jsx>{`
        .infinite-scroll-sensor {
          min-height: 1px;
        }
      `}</style>
    </div>
  )
}

export default InfiniteScroll
